'use client';

import 'swiper/css';

import { gql, GraphQLClient } from 'graphql-request';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import commentTitleImg from '/public/commentTitleImg.svg';
import { GlobalSvgSelector } from '@/lib/GlobalSVGSelector';

import style from './Comment.module.sass';

type StickyMessage = {
	id: number;
	url: string;
	date: number;
	text: string;
	from: {
		display_name: string;
		career: string;
		url: string;
		photo_small: string;
	};
	reactions: {
		lol: number;
		sad: number;
		like: number;
		love: number;
		dislike: number;
		angry: number;
		norm: number;
	};
	userReaction: string | null;
};

type GetStickyMessagesResponse = {
	getStickyMessages: {
		items: StickyMessage[];
	};
};

const client = new GraphQLClient('https://mt.ru/gqapi/main/v3');

const QUERY = gql`
	query {
		getStickyMessages(resourceTypes: [SMI2], limit: 10, offset: 0) {
			items {
				id
				url
				date
				text
				from {
					display_name
					career
					url
					photo_small
				}
				reactions {
					lol
					sad
					like
					love
					dislike
					angry
					norm
				}
				userReaction
			}
		}
	}
`;

export const Comment: React.FC = () => {
	const [pages, setPages] = useState<StickyMessage[]>([]);
	const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
	const swiperRef = useRef<any>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await client.request<GetStickyMessagesResponse>(QUERY);
				setPages(response.getStickyMessages.items);
			} catch (err) {
				console.error('Error fetching data:', err);
			}
		};

		fetchData();
	}, []);
	return (
		<>
			<section className={style.navigation}>
				{currentSlideIndex > 0 && (
					<div
						onClick={() => swiperRef.current?.slidePrev()}
						className={style.arrowleft}
					>
						<GlobalSvgSelector id='left-Arrow' />
					</div>
				)}

				<Swiper
					spaceBetween={50}
					slidesPerView={1}
					autoHeight={true}
					onSlideChange={swiper => setCurrentSlideIndex(swiper.activeIndex)}
					onSwiper={swiper => (swiperRef.current = swiper)}
				>
					{pages.map((el: StickyMessage) => (
						<SwiperSlide key={el.id}>
							<section className={style.wrapper}>
								<div className={style.commentTitle}>
									<Image src={commentTitleImg} alt='commentTitleImg' />
									<h4>Комментарии</h4>
								</div>
								<div className={style.commentMainContent}>
									<Link
										href={el.from.url}
										target='blank'
										className={style.link}
									>
										<div className={style.commentAuthor}>
											<div className={style.commentAvatar}>
												{el.from.photo_small !== '' &&
													el.from.photo_small !== undefined &&
													el.from.photo_small !== null && (
														<Image
															src={el.from.photo_small}
															alt='avatar'
															width={32}
															height={32}
															className={style.commentAvatar}
														/>
													)}
											</div>
											<div className={style.commentAuthorTxt}>
												<span className={style.authorNameDiv}>
													{el.from.display_name}
												</span>
												<span className={style.authorDesc}>
													{el.from.career}
												</span>
											</div>
										</div>
									</Link>
									<Link href={el.url} target='blank' className={style.link}>
										<div className={style.commentTxt}>{el.text}</div>
									</Link>
								</div>
							</section>
						</SwiperSlide>
					))}

					{/* {pages.length > 0 && (
						<SwiperSlide>
							<section className={style.wrapper}>
								<div className={style.commentTitle}>
									<Image src={commentTitleImg} alt='commentTitleImg' />
									<h4>Комментарии</h4>
								</div>
								<div
									className={style.lastSlideContent}
									onClick={() => dispatch(SetIsOpenYandexModal(true))}
								>
									<div className={style.lastCommentTxt}>
										<span className={style.firstLine}>
											Хотите стать экспертом?
										</span>
										<span className={style.secondLine}>
											<div className={style.secondLineHref}>
												Напишите нам на почту!
											</div>
										</span>
									</div>
									<Image src={CommentStar} alt='CommentStar' />
								</div>
							</section>
						</SwiperSlide>
					)} */}
				</Swiper>

				{currentSlideIndex < pages.length -1 && (
					<div
						onClick={() => swiperRef.current?.slideNext()}
						className={style.arrowRight}
					>
						<GlobalSvgSelector id='right-Arrow' />
					</div>
				)}
			</section>
		</>
	);
};
